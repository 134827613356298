import { useIsLoggedIn } from '@dreamstack/auth'
import { trpc } from '@dreamstack/trpc/client'

export const useGetMyProjectIds = () => {
  const isLoggedIn = useIsLoggedIn()
  const { data, error } = trpc.project.getContactProjects.useQuery(undefined, {
    enabled: isLoggedIn,
    trpc: { ssr: false },
    staleTime: 0,
  })
  const projectIds = data?.projectIds
  return { projectIds, error }
}
