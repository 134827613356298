import { useTranslation } from '@dreamstack/i18n'
import { PropertyStatus } from '@dreamstack/web-graphql'
import { useCallback } from 'react'

// Definition FROM: https://docs.google.com/spreadsheets/d/11e_FCccRtK4v9KHlHPHbvDio14-G7fMaLG8oHgXZgMo/edit#gid=0

export const useParsePropertyStatus = () => {
  const t = useTranslation()
  return useCallback(
    (status?: PropertyStatus | null) => {
      switch (status) {
        case PropertyStatus.FREE:
        case PropertyStatus.FREE_STOP_AD:
        case PropertyStatus.FREE_EXCLUSIVE_COOP:
          return t('accentro:available')
        case PropertyStatus.NOT_AVAILABLE:
          return t('accentro:notAvailable')
        case PropertyStatus.BLOCKED:
        case PropertyStatus.PRERESERVED:
        case PropertyStatus.RESERVED:
        case PropertyStatus.RESERVATION_EXTENSION_1:
        case PropertyStatus.RESERVATION_EXTENSION_2:
        case PropertyStatus.RESERVATION_EXTENSION_3:
        case PropertyStatus.DECLARATION_OF_PURCHASE_INTENTION:
          return t('accentro:reserved')
        case PropertyStatus.SOLD:
          return t('accentro:sold')
        // case PropertyStatus.PRERESERVED:
        //   return t('accentro:prereserved')
        default:
          return t('accentro:notAvailable')
      }
    },
    [t]
  )
}
