import { HeadingH1 } from '@dreamstack/feature-components'
import { useTranslation } from '@dreamstack/i18n'
import type { FunctionComponent } from 'react'
import 'twin.macro'

export const PropstackError: FunctionComponent<React.PropsWithChildren<{ error: any }>> = () => {
  const t = useTranslation()
  const errorMsg = t('accentro:error.commonPropStackError')
  return (
    <div tw="p-px-16 bg-accentroRed-100 rounded">
      <HeadingH1>{errorMsg}</HeadingH1>
    </div>
  )
}
