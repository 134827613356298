import { PropertyFilterOverview } from '@dreamstack/feature-components'
import {
  PropstackError,
  ReservationMainLayout,
  useGetMyProjectIds,
} from '@dreamstack/reservation-ui'
import { AdditionalTrackingInfoContextProvider } from '@dreamstack/tracking'
import { useMemo } from 'react'
import { getStaticPropsPlus } from '../../lib/getStaticPropsPlus'

export default function ReservationToolIndex() {
  const { projectIds, error } = useGetMyProjectIds()

  const additionalInfoMemoized = useMemo(() => ({ reservationTool: true }), [])
  return (
    <>
      <AdditionalTrackingInfoContextProvider context={additionalInfoMemoized}>
        <ReservationMainLayout>
          {error ? (
            <PropstackError error={error} />
          ) : (
            <PropertyFilterOverview
              reservationProjectIds={projectIds ?? undefined}
              isReservationUi={true}
            />
          )}
        </ReservationMainLayout>
      </AdditionalTrackingInfoContextProvider>
    </>
  )
}

export const getStaticProps = getStaticPropsPlus({})
